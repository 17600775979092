<template>

    <div>
        <CForm ref="form" class="needs-validation">
            
            <CRow style="margin-bottom: .5rem">

                <CCol col="6">

                    <CInput
                        label="İsim"
                        placeholder="İsim"
                        type="text"
                        v-model="data.name"
                        horizontal
                        required
                        style="margin-bottom: 0rem"
                    />

                </CCol>

                <CCol col="6">
                    <CInput
                        label="Sıra"
                        type="text"
                        horizontal
                        v-model="data.displayOrder"
                        required
                    />
                </CCol>

            </CRow>
            
        </CForm>

        
    </div>

</template>

<script>

    export default{
        name: "contentGroupForm",
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
            actionType:  function(val){
                if(val == 'create')
                    this.data = {...this.params}
            },
        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
            }
        },
    }
</script>